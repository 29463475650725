<template>
  <div id="app">
    <component :is="currentComponent" />
    <div class="loading" v-if="loading">
      <div class="gradient-div">
        <p class="position-absolute top-0 start-50 translate-middle-x">v.1.0</p>
        <div class="spinner-border text-light lst " role="status"></div>
        <p class="position-absolute bottom-0 start-50 translate-middle-x">Loading...</p>
      </div>
    </div>
    <div v-else>
      <nav class="fixed-bottom navbar-expand-sm bg-light" v-if="actMenu">
        <ul class="nav nav-pills nav-fill gap-2 p-2 small rounded-1 shadow-sm colorss" role="tablist" style="--bs-nav-link-color: #ff7e5f; --bs-nav-pills-link-active-color: #fff; --bs-nav-pills-link-active-bg: #ff7e5f;">
          <li class="nav-item" @click="loadComponent('Repost')" role="presentation">
            <i class="bi bi-envelope-open nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('anketsNew')" role="presentation">
            <i class="bi bi-arrow-through-heart-fill nav-link active rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('Chat')" role="presentation">
            <i class="bi bi-chat-heart nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" style="font-size: 1.5rem;"></i>
          </li>
          <li class="nav-item" @click="loadComponent('editPerson')" role="presentation">
            <i class="bi bi-person-fill nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true"></i>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { postData } from './utils/api';
import { mapActions, mapState } from 'vuex';
import createPerson from './components/createPerson.vue';
import AnketsNew from './components/anketsNew.vue';
export default {
  name: 'App',
  components:{
    createPerson,
    AnketsNew,
  },
  data() {
    return {
      loading: true,
      tg: window.Telegram.WebApp,
    };
  },
  computed: {
    ...mapState(['user', 'anket', 'currentComponent','actMenu']),
  },
  methods: {
    ...mapActions(['updateUserData', 'updateAnketData', 'changeComponent','updateMenuData']),
    
    async loaderInfo() {
      try {
        const data = await postData('/', {
          initData:this.tg.initData,
          initDataUnsafe: this.tg.initDataUnsafe,
          platform: this.tg.platform
        });
        if (data.user) {
          this.updateUserData(data.user); // Убираем спред оператор
          this.updateUserData({ token: data.token });
          this.updateAnketData(data.ankets);
          this.loading = false;
          this.updateMenuData(true)
          this.loadComponent('AnketsNew');
        } else {
          this.updateUserData({ token: data.token });
          this.loading = false;
          this.updateMenuData(false)
          this.loadComponent('createPerson');
        }
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    },
    loadComponent(component) {
      this.changeComponent(component);
    },
  },
  mounted() {
    this.loaderInfo();
  },
};
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/loader.PNG');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  z-index: 1000;
}

.loading p {
  color: white;
  font-size: 15px;
  margin-top: 20px;
}

.lst {
  margin-top: 500px;
}

.gradient-div {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: linear-gradient(45deg, #f7776b, #feb47b);
  text-align: center;
}

.colorss {
  background-color: aliceblue;
}
</style>