<template>
  <body class="demo-1">
		<main>
    <div class="loading" v-if="loading">
      <div class="gradient-div">
        <p class="position-absolute top-0 start-50 translate-middle-x"></p>
        <div class="spinner-border text-light lst " role="status"></div>
        <p class="position-absolute text-center ">Загрузка анкет</p>
      </div>
    </div>
			<div class="slideshow" v-else>
				<div class="slides">
					<div
            v-for="(profile, index) in anket"
            :key="index"
            :class="['slide', { 'slide--current': index === 0 }]">
          <div
             class="slide__img"
              :style="{ backgroundImage: `url(/api/users/f1/${profile.avatar})` }"
          ></div>
          <h2 class="slide__title">{{ profile.name }}</h2>
						<p class="slide__desc">{{ profile.about }}<br>
              <span class="badge text-bg-light rounded-pill">{{ calculateAge(profile.birthdate) }} лет</span>
            </p>
					</div>
				</div>
				<nav class="slidenav d-flex gap-5 justify-content-center bubs">
          <div class="d-flex gap-5 justify-content-center bubs ">
            <button class="btn snt btn-outline border-light rounded-circle p-4 lh-1 slidenav__item--prev reverse-animated-button" @click="prevSlide" :disabled="isDisabled" type="button">
              <i class="bi bi-x-lg"></i>
            </button>
            <div class="dust-container-dsl"></div>
            <button class="btn snt2 btn-light rounded-circle p-4 lh-1 slidenav__item--next animated-button" @click="prevSlide" :disabled="isDisabled" type="button">
              <i class="bi bi-heart-fill"></i>
            </button>
            <div class="dust-container-like"></div>
          </div>     
				</nav>
			</div>
		</main>
	</body>
</template>
<script>
import { initializeDemoNavigation } from '../assets/utils/demo'; // Импортируем функцию из demo.js
import { animateButton, animateReverseButton } from '../assets/utils/animbt.js'; // Импортируем функции из animbt.js
import { Slideshow } from '../assets/utils/demo1.js'; // Импортируем из demo1.js
import { mapActions, mapState } from 'vuex';
import { fetchAnkets } from '../utils/api';

export default {
  name: 'anketsNew',
  data() {
    return {
      loading: true,
      isDisabled: false,
      currentSlide:0,
    };
  },
  computed: {
    ...mapState(['user','anket', 'actions','actMenu']),
  },
  methods: {
    ...mapActions(['updateAnketData', 'updateActionsData','updateUserData','updateMenuData']),
    async getFetchAnkets(){
      const token = this.user.token
      let actions = null
      if(this.actions.length === 0){
        actions = false
      }else{
        actions = this.actions
      }
      const anketsUser = await fetchAnkets('/ankets',{token,actions})
      console.log(anketsUser.data)
      this.updateAnketData(anketsUser.data)
      this.updateUserData({ token: anketsUser.token });
      this.loading = false
      this.updateMenuData(false)
    },
    actionsUser(tid,action){
      alert(`${tid} - ${action}`)
      this.updateActionsData(this.actions.push({tid,action}))
    },
    disableButtons() {
    this.isDisabled = true;
    setTimeout(() => {
      this.isDisabled = false;
    }, 1450); // Восстановление активности через 2 секунды
  },
    calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // Кнопочки
    prevSlide() {
    console.log(this.currentSlide)
    if (this.isDisabled) return; // Не выполнять, если кнопки отключены
    this.disableButtons();
    },

    

    },
  mounted() {
    // Инициализируем демо-навигацию
    
    this.getFetchAnkets().then(()=>{
    initializeDemoNavigation();
    new Slideshow(document.querySelector('.slideshow'));
    animateButton();
    animateReverseButton();
    })
    // Инициализируем слайдшоу
    
    


  }
};
</script>
<style scoped>
/* Подключаем стили для компонента */
@import '../assets/css/base.css';
@import '../assets/css/stylebt.css';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  z-index: 1000;
}

.loading p {
  color: white;
  font-size: 15px;
  margin-top: 20px;
}
.gradient-div {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #f7776b, #feb47b);
  text-align: center;
}
</style>