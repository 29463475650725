// src/store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    currentComponent: null,
    actMenu:false,
    anket: [],
    actions:[],
    user: {
      id: null,
      name: '',
      age: null,
      avatar: '',
      searchsex: '',
      sex: '',
      about: '',
      token: '',
    },
  },
  mutations: {
    setCurrentComponent(state, component) {
      state.currentComponent = component;
    },
    SET_ANKET_DATA(state, data) {
      state.anket = data;
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
    SET_ACTIONS_DATA(state, data) {
      state.actions = data;
    },
    SET_MENU_DATA(state, data) {
      state.actMenu = data;
    },
  },
  actions: {
    changeComponent({ commit }, component) {
      commit('setCurrentComponent', component);
    },
    updateAnketData({ commit }, data) {
      commit('SET_ANKET_DATA', data);
    },
    updateUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
    updateActionsData({ commit }, data) {
      commit('SET_ACTIONS_DATA', data);
    },
    updateMenuData({ commit }, data) {
      commit('SET_MENU_DATA', data);
    },
  },
});

export default store;